import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { de } from 'date-fns/locale'
import useAccounts from 'hooks/useAccounts'
import useGetCustomersList from 'hooks/useGetCustomersList'
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { useInView } from 'react-intersection-observer'
import { useToastContext } from '../context/ToastContext'
import { formatDate } from '../helpers/date-helper'
import { getStaticRanges } from '../helpers/datepickerDefaultRanges'
import useLocalStorage from '../hooks/useLocalStorage'
import ActionService from '../services/action.service'
import { CopyButton } from './Button/Buttons'
import { DropDown } from './Common/Inputs'
import Loading from './Common/Loading'
import { ComponentWrapper, Table, TableCell, TableHeaderRow } from './Table/Table'
import { TableBody } from './Table/TableBody'
import { TableHead } from './Table/TableHead'

const Report = () => {
  const staticRanges = getStaticRanges(de)
  const addToast = useToastContext()
  const { accounts, accountsLoading } = useAccounts()
  const { customersList, customersLoading } = useGetCustomersList()

  const invoiceFilterList = [
    { _id: '', name: '-' },
    { _id: 'invoiceNotSend', name: 'Rechnung nicht gesendet' },
  ]

  const [pageState, setPageState] = useState({
    isLoading: true,
    yearList: [],
    monthList: [],
    data: [],
  })

  const begin = new Date(2020, 0, 1)
  const currentDate = new Date()
  const firstDayOfMonth = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)
  const lastDayOfMonth = new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0)
  const [startDate, setStartDate] = useLocalStorage('report-start-date', firstDayOfMonth)
  const [endDate, setEndDate] = useLocalStorage('report-end-date', lastDayOfMonth)
  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate)
    setEndDate(ranges.selection.endDate)
  }

  const selectionRange = {
    startDate: new Date(Date.parse(startDate)),
    endDate: new Date(Date.parse(endDate)),
    key: 'selection',
  }

  const [selectedAccount, setSelectedAccount] = useLocalStorage('report-selected-account', null)
  const [selectedCustomer, setSelectedCustomer] = useLocalStorage('report-selected-customer', null)
  const [selectedInvoiceFilter, setInvoiceFilter] = useLocalStorage('report-invoice-filter', null)

  async function copyAction(dealId, actionId) {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
      data: [],
    }))
    await Promise.all([ActionService.copyAction(dealId, actionId)])
      .then(([actionsResult]) => {
        window.open(`/actions/${actionsResult._id}/update`, '_blank', 'noopener,noreferrer')
        getData()
      })
      .catch((error) => {
        addToast(`Technischer Fehler: ${error}`, 'error')
      })
      .finally(() => {
        setPageState((prev) => ({
          ...prev,
          isLoading: false,
        }))
      })
  }

  async function getData() {
    setPageState((prev) => ({
      ...prev,
      isLoading: true,
      data: [],
    }))
    try {
      const tempSelectedAccount = selectedAccount ?? accounts[0]._id

      ActionService.getReport(
        tempSelectedAccount,
        selectedCustomer,
        new Date(Date.parse(startDate)),
        new Date(Date.parse(endDate)),
        selectedInvoiceFilter
      ).then((result) => {
        setSelectedAccount(tempSelectedAccount)
        setPageState({
          ...pageState,
          isLoading: false,
          data: result,
        })
      })
    } catch (error) {
      addToast('Error:' + error, 'error')
    }
  }

  useEffect(() => {
    if (accounts && customersList) {
      getData()
    }
  }, [
    selectedAccount,
    selectedCustomer,
    selectedInvoiceFilter,
    startDate,
    endDate,
    accounts,
    customersList,
  ])

  if (pageState.isLoading || accountsLoading || customersLoading) {
    return <Loading />
  } else {
    return (
      <>
        <ComponentWrapper>
          <div className="flex">
            <div className="flex-1 pr-2">
              <DropDown
                list={customersList}
                title="Kunde"
                name="selectedCustomer"
                value={selectedCustomer}
                onChange={(event) => setSelectedCustomer(event.target.value)}
              />
              <DropDown
                list={accounts}
                title="Account"
                name="selectedAccount"
                value={selectedAccount}
                onChange={(event) => setSelectedAccount(event.target.value)}
              />
              <DropDown
                list={invoiceFilterList}
                title="Rechnungsfilter"
                name="selectedInvoiceFilter"
                value={selectedInvoiceFilter}
                onChange={(event) => setInvoiceFilter(event.target.value)}
              />
            </div>
            <div className="flex-1 pr-2">
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                minDate={begin}
                weekStartsOn={1}
                locale={de}
                staticRanges={staticRanges}
                inputRanges={[]}
                dateDisplayFormat={'dd.MM.yyyy'}
              />
            </div>
          </div>
        </ComponentWrapper>
        {pageState.data?.dayArray?.length <= 0 ? (
          <h2>Keine Daten</h2>
        ) : (
          <>
            <ComponentWrapper>
              <p>
                <strong>
                  {pageState.data.revenue}€ - Gesamtumsatz im Zeitraum vom{' '}
                  {formatDate(selectionRange.startDate)} bis {formatDate(selectionRange.endDate)}
                </strong>
              </p>
            </ComponentWrapper>
            <div className="border rounded border-blue-200 p-2 mb-2">
              {pageState.data?.dayArray?.map((day) => (
                <>
                  {day.customers.length > 0 && (
                    <Table className="text-sm">
                      <TableHead>
                        <TableHeaderRow>
                          <TableCell>{day.revenue}€</TableCell>
                          <TableCell colSpan={10}>{formatDate(day.date)}</TableCell>
                        </TableHeaderRow>
                      </TableHead>
                      <TableBody>
                        {day.customers.map((customer, index) => (
                          <Customer
                            key={index}
                            customer={customer}
                            selectedAccount={selectedAccount}
                            copyAction={copyAction}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </>
    )
  }
}

export default Report

export const Customer = ({ customer, selectedAccount, copyAction }) => {
  const [{ showDetails, customerDetails }, setShowDetails] = useState({
    showDetails: false,
    customerDetails: {
      countSent: 0,
      countAbm: 0,
      countAbmRate: 0,
      countClicked: 0,
      countClickedRate: 0,
      countOpened: 0,
      countOpenedRate: 0,
      deals: [],
    },
  })

  const [ref, inView] = useInView({ triggerOnce: true })

  useEffect(() => {
    let timeoutId
    if (inView && customer.deals) {
      getData()
    }

    return () => clearTimeout(timeoutId)
  }, [inView])

  async function getData() {
    await ActionService.getReportDetails(
      selectedAccount,
      customer.deals
        .filter((x) => x.bookedMails > 0)
        .map((x) => x._id)
        .join(',')
    )
      .then((result) => {
        setShowDetails({ showDetails: true, customerDetails: result })
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error)
      })
  }

  return (
    <>
      <tr>
        <TableCell colSpan={11}>
          <div className="flex justify-end w-full text-right">
            <ArrowPathIcon
              className="h-6 w-6 cursor-pointer"
              title="Daten abholen"
              onClick={getData}
            />
          </div>
        </TableCell>
      </tr>
      <tr ref={ref} className={showDetails ? 'w-100' : 'hideDetails w-100'}>
        <td style={{ width: '10%' }}>{customer.revenue}€</td>
        <td>
          <a href={`./customers/${customer._id}/update`} target="_blank" rel="noreferrer">
            <strong>{customer.name}</strong>
          </a>
        </td>
        <td style={{ width: '5%' }}>TKP</td>
        <td style={{ width: '5%' }}>ETKP</td>
        <td style={{ width: '5%' }}>CPL</td>
        <td style={{ width: '5%' }}>CPO</td>
        <td className="inactive" style={{ width: '10%' }}>
          Versand
        </td>
        <td className="inactive" style={{ width: '10%' }}>
          Öffner
        </td>
        <td className="inactive" style={{ width: '10%' }}>
          Klicks
        </td>
        <td className="inactive" style={{ width: '10%' }}>
          ABM
        </td>
        <td className="inactive" style={{ width: '10%' }}>
          Bounced
        </td>
      </tr>
      {customerDetails.deals.length > 0
        ? customerDetails.deals.map((deal, index) => (
            <Deal key={index} deal={deal} showDetails={showDetails} copyAction={copyAction} />
          ))
        : customer.deals.map((deal, index) => (
            <Deal key={index} deal={deal} showDetails={showDetails} copyAction={copyAction} />
          ))}
    </>
  )
}

export const Deal = ({ deal, showDetails, copyAction }) => {
  return (
    <>
      <tr style={{ background: 'Azure' }} className={showDetails ? 'w-100' : 'hideDetails w-100'}>
        <td>{deal.revenue}€</td>
        <td>
          <a href={`./deals/${deal._id}/update`} target="_blank" rel="noreferrer">
            {deal.name} ({deal.bookingNumber})
          </a>
          <br />
          <b>
            {deal.invoiceSent != null ? `Rechnung gestellt: ` + formatDate(deal.invoiceSent) : null}
          </b>
          <br />
          <b>Buchungsmenge: {deal.bookedMails}</b>
        </td>
        <td>{deal.tkp}€</td>
        <td className="inactive">
          {deal.countSent > 0 ? `${Number((deal.countSent * 0.49) / 1000).toFixed(2)}€` : ' - €'}
        </td>
        <td>{deal.cpl > 0 ? deal.cpl : ' - '}€</td>
        <td>{deal.cpo > 0 ? deal.cpo : ' - '}€</td>
        <td className="inactive">{deal.countSent}</td>
        <td className="inactive">
          {deal.countOpened} ({deal.countOpenedRate}%)
          <br />
          {deal.countOpenedUnique} ({deal.countOpenedUniqueRate}%)
        </td>
        <td className="inactive">
          {deal.countClicked} ({deal.countClickedRate}%)
          <br />
          {deal.countClickedUnique} ({deal.countClickedUniqueRate}%)
        </td>
        <td className="inactive">
          {deal.countAbm} ({deal.countAbmRate}%)
        </td>
        <td className="inactive">
          {deal.countBounced} ({deal.countBouncedRate}%)
        </td>
      </tr>
      {deal.actions.map((action, index) => (
        <Action
          key={index}
          index={index}
          action={action}
          showDetails={showDetails}
          copyAction={copyAction}
        />
      ))}
    </>
  )
}

export const Action = ({ index, action, showDetails, copyAction }) => {
  return (
    <tr
      key={action._id}
      title={`${action.type} #${index + 1} | ${action.status} | ${
        new Date(action.date).toISOString().split('T')[0]
      } ${action.time}`}
      className={showDetails ? 'w-100' : 'hideDetails w-100'}
    >
      <td></td>
      <td>
        <a href={`./actions/${action._id}/update`} target="_blank" rel="noreferrer">
          {action.type} #{index + 1} {formatDate(action.date)} {action.time} (ID:{' '}
          {action.mailSystemId > 0 ? action.mailSystemId : ' KEINE '})
        </a>
      </td>
      <td>
        <CopyButton onClick={() => copyAction(action.deal, action._id)} />
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td className="inactive">{action.countSent}</td>
      <td className="inactive">
        {action.countOpened} ({action.countOpenedRate}%)
        <br />
        {action.countOpenedUnique} ({action.countOpenedUniqueRate}%)
      </td>
      <td className="inactive">
        {action.countClicked} ({action.countClickedRate}%)
        <br />
        {action.countClickedUnique} ({action.countClickedUniqueRate}%)
      </td>
      <td className="inactive">
        {action.countAbm} ({action.countAbmRate}%)
      </td>
      <td className="inactive">
        {action.countBounced} ({action.countBouncedRate}%)
      </td>
    </tr>
  )
}
